<template>
  <div class="v-container">
    <div class="sub-container">
      <!-- 顶部标题 -->
      <div class="v-title">
        <div class="v_title_juzhong">
          <div class="v-title-left">
            {{ nowTime }}
          </div>
          <div class="v-title-left2">
            <a-select
              label-in-value
              v-model="queryParam"
              style="width: 200px"
              @change="handleChange"
            >
              <a-select-option
                v-for="item in selectOption"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          AI数据可视化看板
          <div class="v-title-right" @click="TuiChu()"><span></span>退出</div>
        </div>
      </div>
      <!-- 中间数据 -->
      <div class="v_container v-container-inner">
        <a-row class="row">
          <!-- 左侧 -->
          <a-col :span="7" style="padding: 0; border: 0" class="left">
            <!-- 设备概述 -->
            <a-col :span="24" class="v-box-one">
              <div class="v-box">
                <div class="v-box-title" :style="wrapStyles">
                  工地绑定率
                  <!-- title="工地绑定率=工地已绑定数量/已派工施工中工地数量；工地未绑定率=工地未绑定数量/已派工施工中工地数量" -->
                  <a-tooltip
                    placement="right"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '45%' }"
                    overlayClassName="popover"
                  >
                    <template slot="title">
                      工地绑定率=工地已绑定数量/已派工施工中工地数量；
                      <br />
                      工地未绑定率=工地未绑定数量/已派工施工中工地数量;
                    </template>
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <div class="utili">
                  <Utilization :bc_id="bc_id + ''"></Utilization>
                </div>
              </div>
            </a-col>
            <!-- 设备使用率 -->
            <a-col :span="24" class="v-box-PieRing2">
              <div class="v-box">
                <div class="v-box-title">
                  设备使用率
                  <a-tooltip
                    placement="right"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '45%' }"
                    overlayClassName="popover"
                  >
                    <template slot="title">
                      设备总数=设备未启用+设备备用+设备已绑定数量；
                      <br />
                      设备绑定率=设备绑定工地数量/设备总数;
                      <!-- <br /> -->
                      <!-- 设备备用率：设备备用数量/设备总数，目前未绑定；
                      <br />
                      设备未启用率:设备未启用数量/设备总数,目前未绑定； -->
                    </template>
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <PieRingShiYong :bc_id="bc_id + ''"></PieRingShiYong>
              </div>
            </a-col>
            <!-- 设备在线率 -->
            <a-col :span="24" class="v-box-PieRing">
              <div class="v-box">
                <div class="v-box-title">
                  设备在线率
                  <a-tooltip
                    placement="right"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '45%' }"
                    overlayClassName="popover"
                  >
                    <template slot="title">
                      设备在线率=设备在线数量/设备已绑定数量；
                      <br />
                      设备离线率=设备离线数量/设备已绑定数量;
                    </template>
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <PieRing :bc_id="bc_id + ''"></PieRing>
              </div>
            </a-col>
          </a-col>
          <!-- 中间地图 -->
          <a-col :span="10" class="container">
            <div class="v-box v-box-map">
              <!-- <div class="map-title">
              <img
                src="../../assets/images/visualize/title_icon.png"
                style="padding-right: 10px"
              />
              在施工工地
              <img
                src="../../assets/images/visualize/title_icon.png"
                style="padding-left: 10px"
              />
            </div> -->
              <div class="map-num-box">
                <div class="map-num" v-for="(num, index) in nums" :key="index">
                  <div>
                    <span class="num_value">{{ num.value }}</span
                    >个
                  </div>
                  <p>{{ num.name.replace("(个)", "") }}</p>
                </div>
              </div>
              <div class="map_out">
                <ChinaMap :bc_id="bc_id + ''"></ChinaMap>
              </div>
            </div>
          </a-col>
          <!-- 右侧 -->
          <a-col :span="7" style="padding: 0; border: 0" class="right">
            <!-- 无人施工统计  -->
            <a-col :span="24" class="right_shigong">
              <div class="v-box v-box-one">
                <div class="v-box-title">
                  无人施工统计
                  <a-tooltip
                    placement="right"
                    title="已绑定设备，无人打卡"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '32%' }"
                    overlayClassName="popover"
                  >
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <Unmanned :bc_id="bc_id + ''"></Unmanned>
              </div>
            </a-col>
            <!-- 工地打卡 -->
            <a-col :span="24" class="right_daka">
              <div class="v-box">
                <div class="v-box-title">
                  工地打卡
                  <a-tooltip
                    placement="right"
                    title="已绑定设备，工地打卡数据统计"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '42%' }"
                    overlayClassName="popover"
                  >
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <NationalRanking :bc_id="bc_id + ''"></NationalRanking>
              </div>
            </a-col>
            <!-- 报警事件管理 -->
            <a-col :span="24" class="right_guanli">
              <div class="v-box v-box-Attention">
                <div class="v-box-title">
                  报警事件管理
                  <a-tooltip
                    placement="right"
                    title="截止昨天 7 天内报警数量，简称“最近7日数据”"
                    :get-popup-container="getPopupContainer"
                    :overlayStyle="{ width: '32%' }"
                    overlayClassName="popover"
                  >
                    <img
                      src="../../assets/images/visualize/title_icon.png"
                      style="padding: 0 10px 0 3px"
                    />
                  </a-tooltip>
                </div>
                <Attention :bc_id="bc_id + ''"></Attention>
              </div>
            </a-col>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import PieRing from "./PieRing.vue";
import PieRingShiYong from "./PieRingShiYong.vue";
import Utilization from "./Utilization.vue";
import NationalRanking from "./NationalRanking.vue";
import Unmanned from "./Unmanned.vue";
import Attention from "./Attention.vue";
import { GetScreenWorkList } from "@/api/screen";
import { GetCompanies } from "@/api/device";
import ChinaMap from "./ChinaMap.vue";

const wrapStyles = {
  // overflow: "hidden",
  // position: "relative",
  // padding: "24px",
  // border: "1px solid #e9e9e9",
};
export default {
  components: {
    ChinaMap,
    PieRing,
    PieRingShiYong,
    Utilization,
    NationalRanking,
    Unmanned,
    Attention,
    GetCompanies,
  },
  data() {
    return {
      nowTime: "", // 当前日期时间
      // nums: ["1", "8", "7", "9", "1"],
      nums: [
        { value: "1", name: "工地总数(个)" },
        { value: "2", name: "施工中工地(个)" },
        { value: "3", name: "施工中绑定设备工地(个)" },
        { value: "4", name: "施工中未绑定设备工地(个)" },
      ],
      // 分公司下拉菜单
      queryParam: [],
      selectOption: [],
      bc_id: "",
      wrapStyles,
    };
  },
  created() {
    this.getTime();
    this.GetCompanies();
    this.GetCompaniesValue();
  },
  mounted() {
    this.initChart();

    // this.handleChange();
  },
  watch: {
    // 监听bc_id的变化
    bc_id(newVal, oldVal) {
      console.log(newVal);
      this.initChart(newVal);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    async initChart(bc_id) {
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      // 为nums赋值
      await GetScreenWorkList(params).then((res) => {
        // console.log(res.data);
        let ws_sum = res.data.ws_sum; //工地总数(个)
        let ws_onwork = res.data.ws_onwork; //施工中工地(个)
        let ws_bind = res.data.ws_bind; //施工中绑定设备工地(个)
        let ws_unbind = res.data.ws_unbind; //施工中未绑定设备工地(个)
        var size1 = res.data;
        // var workSize = size1.length;
        // console.log(res.data);
        // var arr = [];
        // sWorkSize.split("").forEach(function (item) {
        //   //调用split，以空字符串为分隔符切割字符串并返回数组，在数组上调用forEach方法
        //   arr.push(item); //对传入的每个字符用pasreInt转换为数字并压入arr数组
        // });
        // this.nums.value = [ws_sum, ws_onwork, ws_bind, ws_unbind];
        this.nums[0].value = ws_sum;
        this.nums[1].value = ws_onwork;
        this.nums[2].value = ws_bind;
        this.nums[3].value = ws_unbind;
      });
    },
    // 获取当前时间
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    // 退出按钮
    TuiChu() {
      this.$router.push({
        // path: "/DailyMonitor",
        // name: "DailyMonitor",
        path: "/SiteList",
      });
    },
    // 分公司下拉框数据
    GetCompanies() {
      GetCompanies().then((res) => {
        this.selectOption.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.selectOption.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }

        // console.log(this.selectOption);
      });
    },
    // 选择的分公司下拉框的数据
    GetCompaniesValue() {
      GetCompanies().then((res) => {
        // console.log(res);
        this.queryParam.length = 0;
        this.queryParam.push({ value: " ", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.queryParam.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
        // console.log(this.queryParam);
      });
    },
    handleChange(value) {
      this.bc_id = value.key;
      console.log(this.bc_id);
    },
  },
};
</script>
<style lang="less" scoped>
.v-container {
  // background: #000934;
  background: #091423;
  min-width: 1200px;
  overflow: auto;
  width: 100%;
  height: 100%;
}
.sub-container {
  height: 100%;
  width: 100%;
  position: relative;
  // margin: 0 10px;
  // background-image: url("../../assets/images/visualize/bordera.png"),
  //   url("../../assets/images/visualize/borderb.png"),
  //   url("../../assets/images/visualize/borderc.png"),
  //   url("../../assets/images/visualize/borderd.png");
  // background-repeat: no-repeat;
  // background-position: top left, top right, bottom right, bottom left;
}
.v-title {
  // color: #fff;
  // height: 75px;
  // line-height: 75px;
  // font-weight: 600;
  // font-size: 27px;
  // background: url("../../assets/images/visualize/title_bg.png") no-repeat;
  // background-position: center center;
  position: relative;
  color: #66ffff;
  // height: 9%;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;

  .v-title-left {
    position: absolute;
    left: 5%;
    color: #fff;
    font-size: 14px;
    // line-height: 60px;
    top: -8%;
  }
  .v-title-left2 {
    position: absolute;
    left: 20%;
    color: #fff;
    font-size: 14px;
    // line-height: 60px;
    top: -8%;
  }
  .v-title-right {
    position: absolute;
    right: 5%;
    top: 0;
    color: #fff;
    font-size: 18px;
    // line-height: 60px;
    top: -8%;
    font-family: SimHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #66ffff;
    }
  }
}

.v-container-inner {
  position: relative;
  width: 100%;
  height: 91%;
  .row {
    height: 100%;
    .left {
      // position: absolute;
      height: 100%;
      .v-box-one {
        height: 33%;
      }
      .v-box-PieRing {
        // height: 67%;
        // height: 33%;
        height: 35%;
      }
      .v-box-PieRing2 {
        // height: 34%;
        height: 35%;
      }
    }
    .container {
      // height: 100%;
      height: 103%;
    }
    .right {
      height: 100%;

      .right_shigong {
        // height: 34%;
        height: 35%;
      }
      .right_daka {
        // height: 33%;
        height: 34%;
      }
      .right_guanli {
        // height: 33%;
        height: 34%;
      }
    }
  }
}
.ant-row {
  padding: 35px;
}
.ant-col {
  padding: 6px;
  .v-box {
    border: 1px solid #1486e6;
    // background-color: #0a1341;
    background-color: #0a1d34;
    box-shadow: 0px 4px 34px 15px rgba(0, 0, 0, 0.34);
    // background-image: url("../../assets/images/visualize/border1.png"),
    //   url("../../assets/images/visualize/border2.png"),
    //   url("../../assets/images/visualize/border3.png"),
    //   url("../../assets/images/visualize/border4.png");
    // width: 100%;
    height: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: top left, top right, bottom right, bottom left;
    .utili {
      height: 100%;
      // background-color: red;
    }
  }
}

.left {
  /deep/.ant-tooltip-inner {
    // width: 360px;
    width: 165%;
  }
}
/deep/.ant-tooltip-inner {
  // background-color: #84929d;
  color: #ffffff;
  width: 170px;
}
/deep/ .ant-tooltip-arrow::before {
  // background-color: #84929d;
}
// /deep/.ant-tooltip-arrow-content {
//   background-color: #84929d;
// }
// .v-box-one {
//   // height: 300px;
// }
.v-box-map {
  background-color: #fff;
}
// .map-title {
//   font-weight: 500;
//   font-size: 18px;
//   color: #fff;
//   margin-top: 22px;
//   height: 50px;
//   line-height: 50px;
//   background: url("../../assets/images/visualize/text_map.png") no-repeat;
//   background-position: center center;
// }
.map-num-box {
  height: 30%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // background-color: #fff;
  align-items: center;
  // line-height: 21px;
}
.map_out {
  height: 70%;
  // background-color: pink;
  // align-items: center;
}
.num_value {
  // color: #3da0f1;
  background-color: #12294e;
  margin-bottom: 3px;
  font-size: 20px;
  line-height: 40px;
  font-family: FZDaHei-B02S;
  font-weight: 800;
  // color: #37dadd;
}
.map-num {
  // width: 285px;
  background-color: #12294e;
  width: 40%;
  // height: 90px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  font-family: SimHei;
  padding: 3px 5px;
  // margin: 15px 5px 0 5px;
  margin-top: 10px;
  // background: rgba(255, 255, 255, 0.08);
  border-radius: 14px;
}
.v-box-title {
  color: #fff;
  height: 40px;
  // height: 10%;
  font-family: Adobe Heiti Std;
  line-height: 39px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  background-image: url("../../assets/images/visualize/kanban_bgi.png");
  background-repeat: no-repeat;
  background-position-x: 50%;

  .popover {
    background-color: #12294e;
    color: red;
  }
}

// # 局部样式
.v-title-left2 /deep/ .ant-select {
  width: 100%;
  color: #ffffff;
  font-family: SimHei;
  font-size: 16px;
  font-weight: 400;
}
.v-title-left2/deep/ .ant-select-selection--single {
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: 400;
}
.v-title-left2 /deep/ .ant-select-selection__rendered {
  line-height: 36px;
}
.v-title-left2 /deep/ .ant-select-arrow {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.v-title-left2 /deep/ a-select-option {
  color: red;
}
// 设置子菜单展开样式
.ant-menu-submenu > .ant-menu {
  background-color: rgb(16, 71, 83) !important;
}
.ant-menu-submenu-title {
  color: white !important;
}
#components-popover-demo-placement .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
