<template>
  <div class="out">
    <div class="binglie binglie_out">
      <div class="zongshu">
        {{ w_sum }}
        <p>员工总数</p>
      </div>
      <div class="renshu">
        <div class="binglie">
          <div class="daka">
            <div class="inner">
              {{ ws_attndance }}个
              <p>昨日打卡工地</p>
            </div>
          </div>
          <div class="daka">
            {{ w_attendance }}人
            <p>昨日打卡人数</p>
          </div>
        </div>
        <div class="binglie">
          <div class="daka">
            {{ w_attendance_gj }}人
            <p>昨日工程管家打卡</p>
          </div>
          <div class="daka">
            {{ w_attendance_jl }}人
            <p>昨日项目经理打卡</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
// import { GetScreenWorksiteImage } from "@/api/screen";
import { getScreenWorkAttendance } from "@/api/screen";
export default {
  name: "NationalRanking",
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      w_sum: "", //工人总数
      ws_attndance: "", //昨天打卡工地数
      w_attendance: "", //昨天打卡人数
      w_attendance_gj: "", //管家打卡人数
      w_attendance_jl: "", //经理打卡工人数
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    bc_id(newVal, oldVal) {
      // console.log(newVal);
      this.initChart(newVal);
    },
  },
  methods: {
    async initChart(bc_id) {
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await getScreenWorkAttendance(params).then((res) => {
        // console.log(res);
        this.w_sum = res.data.w_sum;
        this.ws_attndance = res.data.ws_attndance;
        this.w_attendance = res.data.w_attendance;
        this.w_attendance_gj = res.data.w_attendance_gj;
        this.w_attendance_jl = res.data.w_attendance_jl;
      });
    },
  },
};
</script>
<style scoped lang="less">
.out {
  height: 93%;
}
.binglie_out {
  height: 100%;
}
.zongshu {
  color: white;
  height: 90%;
  font-size: 25px;
  margin-left: 20px;
  margin-top: 20px;
  p {
    font-size: 16px;
  }
}
.renshu {
  width: 80%;
  color: white;

  // height: 95%;
}
.binglie {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.daka {
  text-align: center;
  // width: 168px;
  width: 100%;
  height: 30%;

  background-color: rgba(13, 69, 148, 0.25);

  // opacity: 0.25;
  border-radius: 10px;
  // margin: 10px 10px;
  margin: 0% 0 5% 5%;

  font-size: 16px;
  font-family: SimHei;
  font-weight: 400;
  color: #ffffff;
  // line-height: 250%;
  p {
    // line-height: 19px;
    color: #ffffff;
    opacity: 1;
  }
}
</style>
