<template>
  <!-- 工地绑定率 -->
  <!-- <div id="pie1" style="height: 230px"></div> -->
  <div id="pie1" style="height: 80%"></div>
</template>
<script>
import * as echarts from "echarts";
import { GetScreenDeviceBinding } from "@/api/screen";

export default {
  name: "Utilization",
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      option: {
        title: {
          text: "施工中工地",
          left: "center",
          top: "center",
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#fff",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
        },

        color: ["#D7D7D7", "#1999D2"],
        series: [
          {
            name: "设备概述",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            // 环形图样式
            // itemStyle: {
            //   borderRadius: 10,
            // },
            labelLine: {
              length: -20,
            },
            label: {
              show: true,
              // color: "#fff9",
              color: "#fff",
              // position: "right",
              padding: [0, 10],
              // fontSize: 16,
              // formatter: "{b}\n{c}",
              formatter: function (data) {
                // console.log(data);
                return (
                  data.name +
                  "\n" +
                  data.percent.toFixed(0) +
                  "%" +
                  "\n" +
                  data.value +
                  "个工地"
                );
              },
            },

            data: [
              { value: 0, name: "设备未绑定" },
              { value: 0, name: "设备已绑定" },
            ],
          },
        ],
      },
      option1: {
        title: {
          text: "施工中工地",
          left: "center",
          top: "center",
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#fff",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "设备概述",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            // 环形图样式
            // itemStyle: {
            //   borderRadius: 10,
            // },
            labelLine: {
              length: 5,
            },
            // label: {
            //   show: true,
            //   // color: "#fff9",
            //   color: "#fff",
            //   // position: "right",
            //   padding: [0, 50],
            //   // fontSize: 16,
            //   // formatter: "{b}\n{c}",
            //   formatter: function (data) {
            //     // console.log(data);
            //     return (
            //       data.percent.toFixed(0) +
            //       "%" +
            //       "\n" +
            //       data.name +
            //       "\n" +
            //       data.value +
            //       "个工地"
            //     );
            //   },
            // },
            data: [
              { value: 0, name: "设备未绑定" },
              { value: 0, name: "设备已绑定" },
            ],
          },
        ],
      },
    };
  },

  mounted() {
    this.initChart();
  },
  watch: {
    bc_id(newVal, oldVal) {
      // console.log(newVal);
      this.initChart(newVal);
    },
  },
  methods: {
    async initChart(bc_id) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie1"));
      // const params = bc_id ? bc_id : "";
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await GetScreenDeviceBinding(params).then((res) => {
        // console.log(res);
        // this.option.series[0].data = res.data;
        var d_sum = res.data.ws_sum;
        var bind = res.data.ws_bind;
        var unbind = res.data.ws_unbind;
        // console.log(unbind);
        // console.log(parseFloat(bind).toFixed(0));
        this.option.title.text = d_sum + "\n" + "已派工施工中";
        this.option.series[0].data[0].value = unbind;
        this.option.series[0].data[1].value = bind;

        this.option1.title.text = d_sum + "\n" + "已派工施工中";
        this.option1.series[0].data[0].value = unbind;
        this.option1.series[0].data[1].value = bind;
      });
      // 绘制图表
      myChart.setOption(this.option);
      myChart.setOption(this.option1);
    },
  },
};
</script>
<style scoped>
#pie1 {
  margin-top: 2%;
  /* background-color: pink; */
}
</style>
