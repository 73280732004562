<template>
  <!-- 设备在线率 -->

  <div id="pie_ring" style="height: 85%"></div>
</template>
<script>
import * as echarts from "echarts";
import { getScreenDeviceDeviceonline } from "@/api/screen";

export default {
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  name: "PieRing",
  data() {
    return {
      res2: [],
      option: {
        title: {
          text: "已绑定",
          x: 20,
          y: 60,
          left: "center",
          top: "center",
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#fff",
            fontSize: 16,
          },
        },
        // #F65656
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   top: "40%",
        //   left: "80%",
        //   textStyle: {
        //     color: "#ffffff",
        //   },
        //   icon: "circle",
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   formatter: function (data) {
        //     // console.log(data);
        //     return data;
        //   },
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        color: ["#6FB419", "#D7D7D7"],
        series: [
          {
            name: "设备使用率",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false, //标签可以重叠
            // 环形图样式
            // itemStyle: {
            //   borderRadius: 10,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            label: {
              show: true,
              // color: "#fff9",
              color: "#fff",
              // formatter: "{b}\n{c}个",
              // formatter: "{b}：{d}%",
              // position: "inner", // 数值显示在内部
              formatter: function (data) {
                // console.log(data);
                return (
                  data.name +
                  "\n" +
                  data.percent.toFixed(0) +
                  "%" +
                  "\n" +
                  data.value +
                  "台"
                );
              },
            },
            // 淡化
            emphasis: {
              label: {
                show: true,
                // fontSize: "40",
                fontWeight: "bold",
                color: "#fff",
              },
            },
            // 引导线
            labelLine: {
              show: true,
            },
            data: [
              { value: 1000, name: "设备在线" },
              { value: 400, name: "设备离线" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    bc_id(newVal, oldVal) {
      // console.log(newVal);
      this.initChart(newVal);
    },
  },
  methods: {
    async initChart(bc_id) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie_ring"));
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await getScreenDeviceDeviceonline(params).then((res) => {
        var d_bind_sum = res.data.d_bind_sum;
        var off = res.data.d_offline; //设备离线
        var on = res.data.d_online; //设备在线
        this.option.title.text = d_bind_sum + "\n" + "已绑定";
        this.option.series[0].data[0].value = on;
        this.option.series[0].data[1].value = off;
        // console.log(on);
        // console.log(res);
        // console.log(params);
      });
      // 绘制图表
      myChart.setOption(this.option);
    },
  },
};
</script>
<style scoped>
/* #pie_ring {
  width: 50%;
} */

canvas {
  /* height: 200px; */
  height: 100%;
}
</style>
