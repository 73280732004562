<template>
  <div id="china_map">
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchData"
      v-show="false"
    ></s-search>
    <!-- <div class="explain_map">
      <p>只展示施工中绑定过摄像头的工地，以设备最后上传的经纬度为准</p>
    </div> -->
    <baidu-map
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @ready="handler"
      class="bm-view"
      :style="{ height: mapHeight }"
    >
      <bm-control>
        <div class="status-con" v-show="false">
          <!-- 只展示施工中-绑定过摄像头的工地 -->
          <div class="status-item" :class="{ status1: device_status === '1' }">
            <img src="../../assets/images/map_success.png" alt="" />

            <span>在线</span>
          </div>
          <div class="status-item" :class="{ status3: device_status === '2' }">
            <img src="../../assets/images/map_error.png" alt="" />

            <span>离线</span>
          </div>

          <div class="status-item">
            <img src="../../assets/images/map_warn.png" alt="" />

            <span>无摄像头</span>
          </div>
        </div>
      </bm-control>
      <!-- 在线状态mark -->
      <bml-marker-clusterer :averageCenter="true" :styles="style1">
        <bm-marker
          class="Marker"
          v-for="p in points1"
          :key="p.ws_id"
          :position="p"
          :dragging="false"
          :top="false"
          :zIndex="99"
          :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
          @click="clickMarker(p)"
        ></bm-marker>
      </bml-marker-clusterer>
      <!-- 离线的marker点 -->
      <bml-marker-clusterer :averageCenter="true" :styles="style2">
        <bm-marker
          class="Marker"
          v-for="p in points2"
          :key="p.ws_id"
          :position="p"
          :dragging="false"
          :top="false"
          :zIndex="99"
          :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
          @click="clickMarker(p)"
        ></bm-marker>
      </bml-marker-clusterer>
      <!-- 无摄像头的marker点 -->
      <bml-marker-clusterer :averageCenter="true" :styles="style3">
        <bm-marker
          class="Marker"
          v-for="p in points3"
          :key="p.ws_id"
          :position="p"
          :dragging="false"
          :top="false"
          :zIndex="99"
          :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
          @click="clickMarker(p)"
        ></bm-marker>
      </bml-marker-clusterer>
      <!-- </bml-marker-clusterer> -->
      <div>
        <bm-info-window
          class="info"
          :position="windowPosition"
          :show="show"
          :autoPan="true"
          @close="infoWindowClose"
          @open="infoWindowOpen"
          ref="InfoWindow"
        >
          <div>
            <!-- 需要添加判断显示  摄像头 在线   离线 -->
            <span>施工中</span>
            <span>
              <span
                v-if="windowPosition.device_status == '1'"
                style="color: green"
              >
                <!-- <img src="../../assets/images/map_success.png" alt="" /> -->
                在线
              </span>
              <span
                v-if="
                  windowPosition.device_status == '2' &&
                  windowPosition.camera != '2'
                "
                style="color: red"
              >
                <!-- <img src="../../assets/images/map_error.png" alt="" /> -->
                离线
              </span>
            </span>
            <span
              v-if="
                windowPosition.camera == '2' &&
                windowPosition.device_status == '2'
              "
              style="color: blue"
            >
              <!-- <img src="../../assets/images/map_warn.png" alt="" /> -->
              无摄像头</span
            >
          </div>
          <!-- 去掉了“直辖市-”-->
          <a @click="jumpToSiteList()" title="点击查看工地信息">{{
            windowPosition.ws_name
          }}</a>
          <div>
            <span>工程管家：</span><span>{{ windowPosition.w_name_gj }}</span
            ><span>{{ windowPosition.gj_phone }}</span>
          </div>
          <div>
            <span>项目经理：</span>{{ windowPosition.w_name_jl
            }}<span>{{ windowPosition.jl_phone }}</span>
          </div>
        </bm-info-window>
      </div>
    </baidu-map>
  </div>
</template>

<script>
import { SSearch } from "@/components";
import { GetSiteMap } from "@/api/site";

import Detail from "../inspection/detail.vue";
import { BmlMarkerClusterer } from "vue-baidu-map";
// import detail from "../inspection/detail.vue";
// 搜索条件
const queryItems = [
  { label: "项目经理", value: "w_name_jl", type: "input" },
  {
    label: "分公司名称",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
    scopedSlots: { customRender: "ws_name" },
  }, //新增

  { label: "工程管家", value: "w_name_gj", type: "input" },
  {
    label: "是否有摄像头",
    value: "camera",
    type: "select",
    selectOption: "Camera",
  }, //新增
  {
    label: "设备状态",
    value: "device_status",
    type: "select",
    selectOption: "SiteDeviceStatus",
  }, //新增

  {
    label: "无人施工天数",
    value: "no_work",
    type: "select",
    selectOption: "NoWorks",
  }, //新增
  // { label: "地区", value: "ws_area", type: "area" },
];
export default {
  name: "SiteMap",
  components: {
    SSearch,
    GetSiteMap,
    BmlMarkerClusterer,
    Detail,
  },
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  data() {
    this.queryItems = queryItems;
    return {
      ws_id: "",
      mapHeight: "400px",
      //   ws_status: "0",
      queryParam: {},
      bcName: "",
      center: { lng: 116.407394, lat: 39.904211 },
      zoom: 3,
      points1: [],
      points2: [],
      points3: [],
      show: false,
      windowPosition: {},
      zai_li_xian: "",
      wu_camera: "",
      device_status: "",
      Camera: "1",
      // 弹出框
      title: "",
      visible: false,
      record: {
        ws_id: "",
        ws_name: "",
      },
      style1: [
        {
          url: require("../../assets/images/map_success.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
      style2: [
        {
          url: require("../../assets/images/map_error.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
      style3: [
        {
          url: require("../../assets/images/map_warn.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
    };
  },
  created() {
    this.ws_id = this.$route.query.ws_id;
    this.fetchData();
    this.$nextTick(() => {
      this.setHeight();
    });
  },
  watch: {
    // 监听分公司查询条件的变化，改变地图中心点的位置
    bc_id(newValue, old) {
      this.fetchMap();
      console.log(newValue, old);
      // console.log(newValue);
      if (newValue == "" || newValue == 0) {
        this.center = "北京市";
      } else if (newValue == 20 || newValue == 8 || newValue == 5) {
        this.center = "天津市";
      } else if (
        newValue == 6 ||
        newValue == 24 ||
        newValue == 3 ||
        newValue == 23 ||
        newValue == 4 ||
        newValue == 25
      ) {
        this.center = "北京市";
      } else if (newValue == 9) {
        this.center = "成都市";
      } else if (newValue == 17) {
        this.center = "大连市";
      } else if (newValue == 15) {
        this.center = "哈尔滨市";
      } else if (newValue == 14) {
        this.center = "济南市";
      } else if (newValue == 13) {
        this.center = "南京市";
      } else if (newValue == 22 || newValue == 10) {
        this.center = "青岛市";
      } else if (newValue == 7) {
        this.center = "石家庄市";
      } else if (newValue == 12) {
        this.center = "武汉市";
      } else if (newValue == 16 || newValue == 19 || newValue == 21) {
        this.center = "西安市";
      } else if (newValue == 18) {
        this.center = "郑州市";
      } else if (newValue == 11) {
        this.center = "重庆市";
      }
    },
  },
  methods: {
    fetchData(data) {
      this.queryParam = data
        ? data
        : {
            // ws_area: "",
            bc_name: this.bc_id != "0" ? this.bc_id : "",
            // w_name: "",
            ws_name: "",
            w_name_gj: "",
            camera: "", //有无摄像头
            // d_status: "",
            no_work: "",
            device_status: "",
            w_name_jl: "",
            ws_id: "",
          };
      this.fetchMap();
    },
    setHeight() {
      let screen = document.body.clientHeight;
      this.mapHeight = 100 + "%";
      // this.mapHeight = screen - 128 + "px";
    },
    handler({ BMap, map }) {
      this.center = "北京市";
      this.zoom = 12;
    },
    async fetchMap() {
      // this.center = this.queryParam.ws_area; // 设置当前地图区域
      let order = [
        "bc_name",
        "ws_name",
        "w_name_jl",
        "w_name_gj",
        "camera",
        "device_status",
        "no_work",
      ];
      let params = {
        ...this.queryParam,
        // ws_status: "",
        // d_status: "",
        // ws_bind_status: "",
        ws_id: this.queryParam.ws_id,
        // bc_name: this.queryParam.bc_name,
        bc_name: this.bc_id == 0 ? "" : this.bc_id,
        ws_name: this.queryParam.ws_name,
        w_name_jl: this.queryParam.w_name_jl, //工地项目经理
        w_name_gj: this.queryParam.w_name_gj, //工地管家
        camera: this.queryParam.camera, //有无摄像头
        device_status: this.queryParam.device_status, //摄像头状态
        no_work: this.queryParam.no_work,
        gj_phone: this.queryParam.gj_phone,
        jl_phone: this.queryParam.jl_phone,
        verify: this.$verify(
          {
            ...this.queryParam,
            ws_id: this.queryParam.ws_id,
            bc_name: this.bc_id == 0 ? "" : this.bc_id,
            ws_name: this.queryParam.ws_name,
            w_name_jl: this.queryParam.w_name_jl, //工地项目经理
            w_name_gj: this.queryParam.w_name_gj, //工地管家
            camera: this.queryParam.camera, //有无摄像头
            device_status: this.queryParam.device_status, //摄像头状态
            no_work: this.queryParam.no_work,
            gj_phone: this.queryParam.gj_phone,
            jl_phone: this.queryParam.jl_phone,
          },
          order
        ),
      };
      // this.bc_id = params["bc_name"] ? params["bc_name"] : "";
      // const points = [];
      const points1 = [];
      const points2 = [];
      const points3 = [];
      await GetSiteMap(params).then((res) => {
        // console.log(res.data[0].ws_name);

        if (res && res.data) {
          res.data.forEach((el) => {
            let icon = "";
            if (el.device_status == "1") {
              icon = require("../../assets/images/map_success.png");
            } else if (el.device_status == "2" && el.camera != "2") {
              icon = require("../../assets/images/map_error.png");
            } else if (el.camera == "2" && el.device_status == "2") {
              icon = require("../../assets/images/map_warn.png");
            }
            if (el.ws_long && el.ws_lat && el.device_status == "1") {
              points1.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                // ws_name: el.ws_name.split(/ /),
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
            if (
              el.ws_long &&
              el.ws_lat &&
              el.device_status == "2" &&
              el.camera != "2"
            ) {
              points2.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
            if (
              el.ws_long &&
              el.ws_lat &&
              el.camera == "2" &&
              el.device_status == "2"
            ) {
              points3.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
          });
        } else {
          this.$message.error("地图返回数据不正确");
        }
        // console.log(res.data);
      });
      // this.points = points;

      setTimeout(() => {
        this.points1 = points1.slice(0, 200);
        this.points2 = points2.slice(0, 200);
        this.points3 = points3.slice(0, 200);
        // console.log("第一个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 500);
        this.points2 = points2.slice(0, 500);
        this.points3 = points3.slice(0, 500);
        // console.log("第二个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 1000);
        this.points2 = points2.slice(0, 1000);
        this.points3 = points3.slice(0, 1000);
        // console.log("第三个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 1500);
        this.points2 = points2.slice(0, 1500);
        this.points3 = points3.slice(0, 1500);
        // console.log("第四个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 2000);
        this.points2 = points2.slice(0, 2000);
        this.points3 = points3.slice(0, 2000);
        // console.log("第五个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1;
        this.points2 = points2;
        this.points3 = points3;
        // console.log("第六个定时器数组长度" + this.points.length);
      });
    },
    clickMarker(p) {
      this.show = true;
      this.windowPosition = p;
      // console.log(this.windowPosition);
      // this.center = "北京市";
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    jumpToSiteList() {
      console.log("jumpToSiteList");
      this.$router.push({
        path: "SiteList",
        query: {
          ws_name: this.windowPosition.ws_name,
        },
      });
    },

    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
#china_map {
  width: 100%;
  height: 100%;
  padding: 5%;

  /* background-color: red; */
}
.BMap_mask {
  height: 100%;
}
.explain_map {
  height: 25px;
  line-height: 15px;
  text-align: left;
  p {
    margin: 0;
    padding: 0;
  }
}
icon {
  width: 30px;
}
.info {
  width: 300px;
}
.bm-view {
  width: 100%;
}
.status-con {
  width: 100px;
  background: #fff;
  border: rgba(0, 0, 0, 0.342) solid 1px;
  // border-radius: 16px;
  // margin: 20px ;
  // display: flex;
}
.status-item {
  margin: 10px 0;
  // padding: 0 15px;
}
.status-item + .status-item {
  border-left: 1px solid #ccc;
}
// .status-item:hover {
//   //   cursor: pointer;
//   //   color: #1890ff;
// }
.status1 {
  color: #52c41a;
}
.status2 {
  // color: #faad14;
  color: #397ef9;
}
.status3 {
  color: #f5222d;
}
.status-icon {
  display: block;
  padding-bottom: 5px;
  font-size: 18px;
}

.TClist {
  height: 40px;
  line-height: 40px;
  span {
    background-color: black;
    color: #fff;
    margin-right: 2px;
    padding: 9px;
    a {
      color: white;
    }
  }
  .zhibo {
    background: url(../../assets/images/site_img/zhibo.png);
  }
  .huifang {
    background: url(../../assets/images/site_img/huifang.png);
  }
  .kaoqin {
    background: url(../../assets/images/site_img/kaoqin.png);
  }
  .kuaizhao {
    background: url(../../assets/images/site_img/kuaizhao.png);
  }
}
.bm-marker-icon /deep/ .BMap_Marker {
  background-size: 100%;
}
/deep/ .BMap_cpyCtrl,
/deep/ .anchorBL {
  display: none;
}
</style>
