<template>
  <div class="pailie">
    <!-- <div class="Sum" style="color: white">总数</div> -->
    <!-- <div id="unmanned" style="height: 250px"></div> -->
    <div id="unmanned"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { GetScreenWorkSiteNothing } from "@/api/screen";

export default {
  name: "Unmanned",
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sum: "",
      option: {
        title: {
          // text: "无人施工工地总数",
          text: "无人施工工地总数", // 副标题显示数据总数
          x: 10,
          y: 10,
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "white",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        // 图例
        // legend: {
        //   orient: "vertical",
        //   top: "10%",
        //   left: "right",
        //   textStyle: {
        //     color: "#ffffff",
        //   },
        //   icon: "circle",
        //   itemWidth: 10,
        //   itemHeight: 10,
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        // color: ["#338AFF", "#10D5D5", "#F65656"],
        color: ["#FEAD16", "#6FB419", "#37BBCA"],

        series: [
          {
            name: "无人施工统计",
            type: "pie",
            large: true,
            // radius: "50%",
            radius: ["18%", "58%"],
            center: ["60%", "50%"],
            // avoidLabelOverlap: false, //标签可以重叠
            label: {
              show: true,
              color: "#fff",
              position: "inner", // 数值显示在内部
              // formatter: "{b}\n{c}个",
              formatter: function (data) {
                return data.percent.toFixed(0) + "%";
              },
            },
            data: [
              { value: 580, name: "累计30天以上" },
              { value: 380, name: "累计10-30天" },
              { value: 735, name: "累计10天" },
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "outer",
                  color: "#FFFFFF",
                  // text: "SimHei",
                  fontSize: 12,
                  // fontWeight: "bold",
                  align: "left",
                  formatter: function (p) {
                    //指示线对应文字，说明文字
                    return (
                      p.data.value + "\n{icon|●}{name|" + p.data.name + "}"
                    );
                  },
                  rich: {
                    icon: {
                      fontSize: 12,
                      color: "inherit",
                      // padding: [0, 0, 0, 0],
                    },
                    name: {
                      fontSize: 12,
                      padding: [0, 0, 0, 4],
                      color: "#fefefe",
                    },
                    value: {
                      color: "#fefefe",
                      // padding: [-10, 0, 0, 4],
                    },
                  },
                },

                labelLine: {
                  //指示线状态
                  show: false,
                },
              },
            },
          },
          {
            name: "无人施工统计",
            type: "pie",
            // radius: "50%",
            radius: ["18%", "58%"],
            center: ["60%", "50%"],
            // avoidLabelOverlap: false, //标签可以重叠
            label: {
              show: true,
              color: "#FFFFFF",
              position: "inner", // 数值显示在内部
              // formatter: "{b}\n{c}个",
              formatter: function (data) {
                return data.percent.toFixed(0) + "%";
              },
            },
            data: [
              { value: 380, name: "累计30天以上" },
              { value: 580, name: "累计10-30天" },
              { value: 735, name: "累计10天" },
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "inner",
                  color: "#FFFFFF",
                  text: "SimHei",
                  // fontSize: 19,
                  // fontWeight: "bold",
                  align: "left",
                  formatter: function (data) {
                    // console.log(data);
                    return data.percent.toFixed(0) + "%";
                  },
                },

                labelLine: {
                  //指示线状态
                  show: false,
                },
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    bc_id(newVal, oldVal) {
      // console.log(newVal);
      this.initChart(newVal);
    },
  },
  methods: {
    async initChart(bc_id) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("unmanned"));
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      // 绘制图表
      await GetScreenWorkSiteNothing(params).then((res) => {
        // this.option.series[0].data = res.data;
        var ws_sum = res.data.ws_sum;
        var ws_ten = res.data.ws_ten;
        var ws_thirty = res.data.ws_thirty;
        var ws_overthirty = res.data.ws_overthirty;
        this.option.title.text = ws_sum + "\n" + "无人施工" + "\n" + "工地总数";
        this.option.series[0].data[0].value = ws_overthirty;
        this.option.series[0].data[1].value = ws_thirty;
        this.option.series[0].data[2].value = ws_ten;
        this.option.series[1].data[0].value = ws_overthirty;
        this.option.series[1].data[1].value = ws_thirty;
        this.option.series[1].data[2].value = ws_ten;
        // console.log(res.data);
        // console.log(ws_overthirty);
      });
      myChart.setOption(this.option);
    },
  },
};
</script>
<style scoped>
.pailie {
  height: 90%;
}
#unmanned {
  margin-top: 1%;
  height: 95%;
}
</style>
