<template>
  <div class="out">
    <div class="renshu">
      <div class="binglie">
        <div class="daka baojing">
          <div class="binglie">
            <div>
              <span class="xiaoshou" @click="GoAlarmEventCY()"
                >{{ this.smoke_sum }}<span class="danwei_ci">次</span></span
              >
              <p>
                <!-- <img src="../../assets/images/kanban_img/stopsmoking.png" /> -->
                抽烟报警(7日)
              </p>
            </div>
            <div>
              <span class="xiaoshou" @click="GoAlarmEventCY_zuori()">
                {{ this.smoke_yesterday }}<span class="danwei_ci">次</span
                ><span class="zuorixinzeng"
                  ><span v-if="this.smokeAdd >= 0" class="zuorixinzeng">+</span
                  >{{ this.smokeAdd }}</span
                ></span
              >
              <p>昨日报警</p>
            </div>
          </div>
        </div>
        <div class="daka shexiangtou">
          <div class="binglie">
            <div>
              <span class="xiaoshou" @click="GoSiteNeatSXTWQ()"
                >{{ this.camera_sum }}<span class="danwei_ci">次</span></span
              >
              <p>
                <!-- <img
                  src="../../assets/images/kanban_img/shebeikongzhi_shexiangtou.png"
                /> -->
                摄像头歪曲(7日)
              </p>
            </div>
            <div>
              <span class="xiaoshou" @click="GoSiteNeatSXTWQ_zuori()">
                {{ this.camera_yesterday }}<span class="danwei_ci">次</span
                ><span class="zuorixinzeng"
                  ><span v-if="this.cameraAdd >= 0" class="zuorixinzeng">+</span
                  >{{ this.cameraAdd }}</span
                ></span
              >
              <p>昨日报警</p>
            </div>
          </div>
        </div>
      </div>
      <div class="binglie">
        <div class="daka gongzhuang">
          <div class="binglie">
            <div>
              <span class="xiaoshou" @click="GoAlarmEventMCGZ()"
                >{{ this.clothes_sum }}<span class="danwei_ci">次</span></span
              >
              <p>
                <!-- <img src="../../assets/images/kanban_img/gongzuofu.png" /> -->
                没穿工装(7日)
              </p>
            </div>
            <div>
              <span class="xiaoshou" @click="GoAlarmEventMCGZ_zuori()"
                >{{ this.clothes_yesterday }}<span class="danwei_ci">次</span
                ><span class="zuorixinzeng"
                  ><span v-if="this.clothesAdd >= 0" class="zuorixinzeng"
                    >+</span
                  >{{ this.clothesAdd }}</span
                ></span
              >
              <p>昨日报警</p>
            </div>
          </div>
        </div>
        <div class="daka zhengjiedu">
          <div class="binglie">
            <div>
              <span class="xiaoshou" @click="GoSiteNeatCha()"
                >{{ this.factory_sum }}<span class="danwei_ci">次</span></span
              >
              <p>
                <!-- <img src="../../assets/images/kanban_img/ganjing.png" /> -->
                工地整洁度差(7日)
              </p>
            </div>
            <div>
              <span class="xiaoshou" @click="GoSiteNeatCha_zuori()">
                {{ this.factory_yesterday }}<span class="danwei_ci">次</span
                ><span class="zuorixinzeng"
                  ><span v-if="this.factoryAdd >= 0" class="zuorixinzeng"
                    >+</span
                  >{{ this.factoryAdd }}</span
                ></span
              >
              <p>昨日报警</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getScreenEventcount } from "@/api/screen";

// // 当鼠标指针位于元素上方时事件！
//     $("#name").mouseover(function(){
//        $("#selected").removeClass("border");
//        $("#selected").css("border","1px solid #707070");
//     });
export default {
  name: "Attention",
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [],
      smoke_sum: "0", //烟报警总数（7天）
      smoke_yesterday: "0", //抽烟昨日报警数
      camera_sum: "0", //摄像头扭曲总数（7天）
      camera_yesterday: "0", //摄像头扭曲昨日报警数
      clothes_sum: "0", //未穿工装报警总数（7天）
      clothes_yesterday: "0", //未穿工作昨日报警数
      factory_sum: "0", //工地整洁度差总数（7天）
      factory_yesterday: "0", //工地整洁度差昨日报警 数
      // columns,
      yesterdayStartTime: "",
      yesterdayEndTime: "",
      smokeAdd: "", //抽烟新增
      cameraAdd: "", //摄像头新增
      clothesAdd: "", //未穿工装新增
      factoryAdd: "", //工地整洁新增
    };
  },
  created() {
    this.getScreenEventcount();

    /**
     * 可以根据转入的天数获取前面某天的开始与结束时间戳
     * @param {number} num 前面几天
     */
    this.getStartEndTime();
  },
  watch: {
    bc_id(newVal, oldVal) {
      console.log(newVal);
      this.getScreenEventcount(newVal);
    },
  },
  methods: {
    async getScreenEventcount(bc_id) {
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await getScreenEventcount(params).then((res) => {
        this.smoke_sum = res.data.smoke_sum;
        this.smoke_yesterday = res.data.smoke_yesterday;
        this.camera_sum = res.data.camera_sum;
        this.camera_yesterday = res.data.camera_yesterday;
        this.clothes_sum = res.data.clothes_sum;
        this.clothes_yesterday = res.data.clothes_yesterday;
        this.factory_sum = res.data.factory_sum;
        this.factory_yesterday = res.data.factory_yesterday;
        this.smokeAdd = res.data.smoke_add * 1;
        this.cameraAdd = res.data.camera_add * 1;
        this.clothesAdd = res.data.clothes_add * 1;
        this.factoryAdd = res.data.factory_add * 1;

        // console.log(res.data);
      });
    },
    getStartEndTime(num = 1) {
      // 一天的毫秒数
      const MillisecondsADay = 24 * 60 * 60 * 1000 * num;
      // 今日开始时间戳
      const todayStartTime = new Date(
        new Date().setHours(0, 0, 0, 0)
      ).getTime();
      // 今日结束时间戳
      const todayEndTime = new Date(
        new Date().setHours(23, 59, 59, 999)
      ).getTime();

      // 昨日开始时间戳
      const yesterdayStartTime = todayStartTime - MillisecondsADay;
      // 昨日结束时间戳
      const yesterdayEndTime = todayEndTime - MillisecondsADay;
      this.yesterdayStartTime = yesterdayStartTime / 1000;
      this.yesterdayEndTime = yesterdayEndTime.toString().slice(0, 10) * 1;
      // console.log(this.yesterdayStartTime, this.yesterdayEndTime);
      return [yesterdayStartTime, yesterdayEndTime];
    },

    // 按钮
    GoAlarmEventCY() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: { e_events: "smoking", bc_id: this.bc_id },
      });
    },
    GoAlarmEventCY_zuori() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          e_time_start: this.yesterdayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "smoking",
          bc_id: this.bc_id,
        },
      });
    },
    GoAlarmEventMCGZ() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: { e_events: "noclothes", bc_id: this.bc_id },
      });
    },
    GoAlarmEventMCGZ_zuori() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          e_time_start: this.yesterdayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "noclothes",
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatSXTWQ() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        params: {
          e_events: "4",
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatSXTWQ_zuori() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        params: {
          e_time_start: this.yesterdayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "4",
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatCha() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        path: "/SiteNeat",
        params: { e_events: "3", bc_id: this.bc_id },
      });
    },
    GoSiteNeatCha_zuori() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        path: "/SiteNeat",
        params: {
          e_time_start: this.yesterdayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "3",
          bc_id: this.bc_id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.out {
  height: 90%;
  // background-color: #fff;
}
.renshu {
  color: white;
  // font-size: 25px;
  font-size: 30px;
  height: 90%;
  // height: 225px;
}

.binglie {
  display: flex;
  height: 40%;
  margin: 2% 0;
  // background-color: red;
  justify-content: space-around;
  align-items: center;
  font-size: 25px;
}

.daka {
  text-align: center;
  width: 45%;
  font-family: SimHei;
  font-weight: 400;
  // margin-top: 10px;
}

p {
  // font-size: 12px;
  font-size: 16px;
}
// .baojing p {
//   // color: #ff6779;
// }
// .shexiangtou p {
//   // color: #2ad89f;
// }
// .gongzhuang p {
//   // color: #ffd676;
// }
// .zhengjiedu p {
//   // color: #2a9cdd;
// }
.xiaoshou {
  cursor: pointer;
}
// .baojing span {
//   // color: #37dadd;
//   color: #ff6779;
// }
// .shexiangtou span {
//   color: #2ad89f;
// }
// .gongzhuang span {
//   color: #ffd676;
// }
// .zhengjiedu span {
//   color: #2a9cdd;
// }
span {
  color: #37dadd;
}
.danwei_ci {
  font-size: 14px;
  color: white;
}
.zuorixinzeng {
  color: red;
  font-size: 14px;
  font-weight: 800;
}
.xiaoshou {
  cursor: pointer;
}
</style>
