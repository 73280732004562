<template>
  <!-- 设备使用率 -->
  <div id="pie_ring2" style="height: 85%"></div>
</template>
<script>
import * as echarts from "echarts";
import { GetScreenDeviceOnoff } from "@/api/screen";

export default {
  props: {
    bc_id: {
      type: String,
      default: "",
    },
  },
  name: "PieRing",
  data() {
    return {
      res2: [],

      option2: {
        title: {
          text: "未绑定",
          x: 20,
          y: 60,
          left: "center",
          top: "center",
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#fff",
            fontSize: 16,
          },
        },
        // #F65656
        tooltip: {
          trigger: "item",
        },

        // legend: {
        //   orient: "vertical",
        //   top: "27%",
        //   left: "80%",
        //   textStyle: {
        //     color: "#ffffff",
        //     // fontSize: 18,
        //   },
        //   icon: "circle",
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   formatter: function (data) {
        //     // console.log(data);
        //     return data;
        //   },
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        color: ["#F39A27", "#BDBE05", "#03A7EF"],
        series: [
          {
            name: "设备使用率",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "51%"],
            avoidLabelOverlap: false, //标签可以重叠
            // 环形图样式
            // itemStyle: {
            //   borderRadius: 10,
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            label: {
              show: true,
              // color: "#fff9",
              color: "#fff",
              // formatter: "{b}\n{c}个",
              // formatter: "{b}：{d}%",
              // position: "inner", // 数值显示在内部
              padding: [0, 0],
              formatter: function (data) {
                // console.log(data);
                return (
                  data.name +
                  "\n" +
                  data.percent.toFixed(0) +
                  "%" +
                  "\n" +
                  data.value +
                  "台"
                );
              },
            },
            // 淡化
            emphasis: {
              label: {
                show: true,
                // fontSize: "40",
                fontWeight: "bold",
                color: "#fff",
              },
            },
            // 引导线
            labelLine: {
              show: true,
            },
            data: [
              { value: 1000, name: "设备未启用" },
              { value: 400, name: "设备备用" },
              { value: 400, name: "设备已绑定" },
              // { value: 400, name: "设备入库" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.initChart2();
  },
  watch: {
    bc_id(newVal, oldVal) {
      // console.log(newVal);

      this.initChart2(newVal);
    },
  },
  methods: {
    async initChart2(bc_id) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie_ring2"));
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await GetScreenDeviceOnoff(params).then((res) => {
        // console.log(res);
        var d_bind_sum = res.data.d_sum; //未绑定总数 32
        var off = res.data.d_unenabled; //未启用 0
        var on = res.data.d_spare; //备用 30
        var bad = res.data.d_bind; //设备已绑定  0
        // var d_stock = res.data.d_stock; //设备入库数
        this.option2.title.text = d_bind_sum + "\n" + "设备总数";
        this.option2.series[0].data[1].value = on;
        this.option2.series[0].data[0].value = off;
        this.option2.series[0].data[2].value = bad;
        // this.option2.series[0].data[3].value = d_stock;
        // this.res2 = this.option2.series[0].data;
        // console.log(this.res2);
      });
      // 绘制图表
      myChart.setOption(this.option2);
    },
  },
};
</script>
<style scoped>
canvas {
  /* height: 200px; */
  height: 100%;
}
#pie_ring2 {
  /* margin-top: 2%; */
  /* background-color: pink; */
  /* top: 5px; */
}
</style>
